// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-security-js": () => import("./../../../src/pages/app_security.js" /* webpackChunkName: "component---src-pages-app-security-js" */),
  "component---src-pages-controls-checklist-js": () => import("./../../../src/pages/controls-checklist.js" /* webpackChunkName: "component---src-pages-controls-checklist-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-risk-profile-js": () => import("./../../../src/pages/risk-profile.js" /* webpackChunkName: "component---src-pages-risk-profile-js" */),
  "component---src-pages-sdlc-js": () => import("./../../../src/pages/sdlc.js" /* webpackChunkName: "component---src-pages-sdlc-js" */)
}

